import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";

const TermsPage = () => {
  const { t, i18n } = useTranslation();
  return (
    <Layout>
      <SEO title={t("terms.html-title")} />
      <div className="fl-grid" style={{ marginTop: 120 }}>
        <h1 className="fl-centered-half fl-center">
          <Trans i18nKey="terms.title" />
        </h1>
      </div>
      <div className="fl-grid fl-three-cards" style={{ marginTop: 80, marginBottom: 80 }}>
        <a href="/CGUTransporteurs-Fretlink-2020-09-01.pdf">
          <h2>
            <Trans i18nKey="terms.carriers.title" />
          </h2>
          <Trans parent="p" i18nKey="terms.carriers.p-our-current" />
          <p className="fl-link-next">
            <Trans i18nKey="terms.carriers.read-it" />
          </p>
        </a>
        <a href="/FR_Conditions-Generales-D-Utilisation_01-08-2019.pdf">
          <h2>
            <Trans i18nKey="terms.shippers.title" />
          </h2>
          <Trans parent="p" i18nKey="terms.shippers.p-our-current" />
          <p className="fl-link-next">
            <Trans i18nKey="terms.shippers.read-it" />
          </p>
        </a>
        <a href={`/${i18n.language}/mentions-legales/`}>
          <h2>
            <Trans i18nKey="terms.users.title" />
          </h2>
          <Trans parent="p" i18nKey="terms.users.p-our-current" />
          <p className="fl-link-next">
            <Trans i18nKey="terms.users.read-it" />
          </p>
        </a>
      </div>

      <div className="fl-grid" style={{ marginTop: 80, marginBottom: 80 }}>
        <p className="fl-centered-half fl-center fl-link-next">
          <a href={`/${i18n.language}/confidentialite/`}>
            <Trans i18nKey="terms.see-also-our-privacy-policy" />
          </a>
        </p>
      </div>
    </Layout>
  );
};

export default TermsPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
